import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';
import { SignalRGroups } from 'src/app/app.signalr-group.constants';

import { JobDto } from 'src/app/models/job-dto.model';

import { ActionType } from 'src/app/core/enums/action-type.enum';
import { AppList } from '../../../core/enums/application-type.enum';
import { DataDogAction } from 'src/app/core/enums/datadog-action.enum';
import { FeatureType } from 'src/app/core/enums/feature-type.enum';
import { JobStatus } from 'src/app/core/enums/job-status.enum';
import { LanguageType } from '../../../core/enums/language-type.enum';
import { SignalRConnectionStatus } from 'src/app/core/enums/signalr-connection-status.enum';

import { AppDataService } from 'src/app/services/app-data.service';
import { CommonService } from 'src/app/services/common.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataDogService } from 'src/app/services/data-dog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FileStoreService } from 'src/app/services/file-store.service';
import { LogService } from 'src/app/services/log.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from 'src/app/services/signalr.service';

import { ConfirmDialogComponent as ConfirmDialog } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { StartNewComparisonComponent } from '../../start-new-comparison/start-new-comparison.component';
import { UsageReportComponent } from '../../usage-report/usage-report.component';
import { UserType } from 'src/app/core/enums/user-type.enum';

@Component({
  selector: 'app-dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['./dashboard-grid.component.css'],
})
export class DashboardGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() jobInfo!: JobDto;

  public readonly appConstants: typeof AppConstants = AppConstants;
  public readonly featureType: typeof FeatureType = FeatureType;
  public readonly jobStatus: typeof JobStatus = JobStatus;
  public readonly appList: typeof AppList = AppList;
  public readonly languageType: typeof LanguageType = LanguageType;
  public readonly dataDogActions: typeof DataDogAction = DataDogAction;
  public readonly userType: typeof UserType = UserType;

  public displayedColumns: string[] = [
    'JobId',
    'TypeId',
    'JobName',
    'CompanyName',
    'EngFile',
    'EngPageCount',
    'ChFile',
    'ChPageCount',
    'Glossary',
    'Status',
    'CreationDate',
    'Action',
  ];
  public dataSource: MatTableDataSource<JobDto> = new MatTableDataSource<JobDto>();
  public isNoData = false;
  public readonly jobSearchControl = new FormControl();
  public toggleControl!: FormControl;
  public isProgressBarShown = false;
  public defaultPageSize: number = 10;
  public defaultPageIndex: number = 0;
  private jobListGroup: string = '';
  private groupJoined: boolean = false;

  private subscription!: Subscription;
  private signalrConnectionSubscription?: Subscription;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    private fileStoreService: FileStoreService,
    private signalRService: SignalRService,
    public appDataService: AppDataService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.toggleControl = new FormControl(this.dashboardService.isToggleOn);
    if (!this.appDataService.user.isAdmin && !this.appDataService.user.isSuperAdmin) {
      this.displayedColumns = this.displayedColumns.filter((x) => x !== 'CompanyName');
    }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.signalRService.leaveGroup(this.jobListGroup);
    this.signalRService.hubConnection.off('BroadcastMessage');
    if (this.signalrConnectionSubscription) {
      this.signalrConnectionSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.groupJoined = false;
      this.resetSignalRGroup();
      this.joinSignalRGroup();
      this.signalrConnectionSubscription = this.signalRService.connectionStateSubject.subscribe(() => {
        //leave group when connection is reset
        if (this.groupJoined) {
          this.signalRService.leaveGroup(this.jobListGroup);
        }
        this.groupJoined = false;
        this.joinSignalRGroup();
      });

      this.subscription = this.dashboardService.roleChanged$.subscribe((roleId: number) => {
        //leave group on role change
        this.signalRService.leaveGroup(this.jobListGroup);
        this.groupJoined = false;
        this.appDataService.user.primaryFunctionalRoleIdentity = roleId;
        this.resetSignalRGroup();
        this.joinSignalRGroup();
        this.dashboardService.dashboardListPageIndex = this.defaultPageIndex;
        this.dashboardService.dashboardListPageSize = this.paginator.pageSize;
        this.GetAllJobs(this.dashboardService.dashboardListPageIndex, this.dashboardService.dashboardListPageSize);
      });
      if (this.appDataService.user.primaryFunctionalRoleIdentity!) {
        this.refreshJobTable();
        this.subscribeSignalRMessages();
      }
    });
  }

  private resetSignalRGroup(): void {
    this.jobListGroup = SignalRGroups.JobList(
      this.appDataService.user.primaryFunctionalRoleIdentity!,
      this.appDataService.user.companyIdentity,
      this.appDataService.user.isAdmin,
      this.appDataService.user.isSuperAdmin,
      this.appDataService.user.isExternalUser!
    );
  }

  public filterJobDetails(): void {
    const filterValue = this.jobSearchControl.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.isNoData = this.dataSource.filteredData.length <= 0;
  }

  private filterTableData(pageIndex: number, pageSize: number): void {
    this.isNoData = this.dataSource.data.length === 0;
    this.jobSearchControl.setValue('');
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageIndex = pageIndex;
    this.dataSource.paginator.pageSize = pageSize;
    this.dataSource.paginator.page.next({
      length: this.dataSource.filteredData.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    });
  }

  public toggleFilter(event: any): void {
    this.dashboardService.isToggleOn = event.checked;
    this.refreshJobTable();
  }

  public refreshJobTable(isRefreshClicked: boolean = false): void {
    if (isRefreshClicked) {
      this.dashboardService.dashboardListPageIndex = 0;
    }
    this.GetAllJobs(this.dashboardService.dashboardListPageIndex, this.dashboardService.dashboardListPageSize).then(() => {
      this.filterJobDetails();
    });
  }

  private GetAllJobs(pageIndex: number, pageSize: number) {
    return new Promise((resolve) => {
      this.isProgressBarShown = true;
      this.dashboardService.GetAllJobs(this.dashboardService.isToggleOn).subscribe({
        next: (data) => {
          data.forEach((value) => {
            value.typeName = this.getFeatureType(value.typeId);
          });
          this.dataSource.data = [...data];
          this.filterTableData(pageIndex, pageSize);
          this.isProgressBarShown = false;
          resolve(true);
        },
        error: (err) => {
          this.isProgressBarShown = false;
          if (err?.status === 401) {
            this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
          } else {
            this.notificationService.error(this.appConstants.JobListTitle, err);
          }
        },
      });
    });
  }

  public openFile(blobName: string, fileName: string, jobIdentity?: string) {
    if (jobIdentity) {
      DataDogService.logDownloadFile(jobIdentity, fileName);
    }
    this.fileStoreService.downloadFile(blobName, fileName);
  }

  private GetBookmarkedPagesReport(jobIdentity: string): void {
    this.dashboardService.GetBookmarkedPagesReport(jobIdentity).subscribe({
      next: (data) => {
        DataDogService.logDownloadFile(jobIdentity, data.fileName);
        this.fileStoreService.downloadFileFn(data.fileBytes, data.fileName);
      },
      error: (err) => {
        this.notificationService.error(this.appConstants.ExportBookmarkReportTitle, err);
      },
    });
  }

  private GetLQAReport(jobIdentity: string): void {
    this.dashboardService.GetLQAReport(jobIdentity).subscribe({
      next: (data) => {
        DataDogService.logDownloadFile(jobIdentity, data.fileName);
        this.fileStoreService.downloadFileFn(data.fileBytes, data.fileName);
      },
      error: (err) => {
        this.notificationService.error(this.appConstants.ExportAnnotationDetailsReportTitle, err);
      },
    });
  }

  private downloadAnnotatedPdf(jobIdentity: string, languageTypeId: number): void {
    this.isProgressBarShown = true;

    this.dashboardService.GetAnnotatedPages(jobIdentity, languageTypeId).subscribe({
      next: (data) => {
        DataDogService.logDownloadFile(jobIdentity, data.fileName);
        this.fileStoreService.downloadFileFn(data.fileBytes, data.fileName);
        this.isProgressBarShown = false;
      },
      error: (err) => {
        this.isProgressBarShown = false;
        this.notificationService.error(
          languageTypeId == LanguageType.English
            ? this.appConstants.DownloadFile1AnnotatedPdfTitle
            : this.appConstants.DownloadFile2AnnotatedPdfTitle,
          err
        );
      },
    });
  }

  private getAnnotationSummaryReport(jobIdentity: string): void {
    this.isProgressBarShown = true;
    this.dashboardService.GetAnnotationSummaryReport(jobIdentity).subscribe({
      next: (data) => {
        DataDogService.logDownloadFile(jobIdentity, data.fileName);
        this.fileStoreService.downloadFileFn(data.fileBytes, data.fileName);
        this.isProgressBarShown = false;
      },
      error: (err: string) => {
        this.isProgressBarShown = false;
        this.notificationService.error(this.appConstants.ExportAnnotationSummaryReportTitle, err);
      },
    });
  }

  private getFeatureType(typeId: number): string {
    return typeId !== FeatureType.Chinese_Characters_Conversion_Check
      ? FeatureType[typeId]?.replace(/_/gi, ' ')
      : AppConstants.ChineseCharactersConversionCheck;
  }

  public CompareFile(englishFileId: number, chineseFileId: number, jobIdentity: number, fileTypeId: number, id: number) {
    this.dashboardService.dashboardListPageIndex = this.paginator.pageIndex;
    this.dashboardService.dashboardListPageSize = this.paginator.pageSize;
    DataDogService.setUserProperty;
    return this.router.navigate(['/file-compare'], {
      state: { id: id },
      queryParams: {
        jobId: jobIdentity,
        fileId1: englishFileId,
        fileId2: chineseFileId,
        typeId: fileTypeId,
      },
    });
  }

  public navigateToGlossaryDashboard() {
    this.dashboardService.dashboardListPageIndex = this.paginator.pageIndex;
    this.dashboardService.dashboardListPageSize = this.paginator.pageSize;
    return this.router.navigate(['/glossaries']);
  }

  public startNewComparisonJob(): void {
    const dialogRef = this.dialogService.open(StartNewComparisonComponent, { width: '500px' });
    this.dialogService.confirmed(dialogRef).subscribe((data) => {
      if (data) {
        this.dashboardService.dashboardListPageIndex = this.defaultPageIndex;
        this.filterTableData(this.dashboardService.dashboardListPageIndex, this.dashboardService.dashboardListPageSize);
      }
    });
  }

  public closeJob(jobIdentity: string): void {
    this.dashboardService.dashboardListPageIndex = this.paginator.pageIndex;
    this.dashboardService.dashboardListPageSize = this.paginator.pageSize;
    const dialogRef = this.dialogService.open(ConfirmDialog, AppConstants.CloseJobConfirmDialogOptions);
    this.dialogService.confirmed(dialogRef).subscribe((confirmed) => {
      if (confirmed) {
        this.dashboardService.CloseJob(jobIdentity).subscribe({
          next: () => {
            this.notificationService.success(AppConstants.CloseJobConfirmationTitle, AppConstants.CloseJobConfirmationSuccessMessage);
          },
          error: (err) => {
            if (err === AppConstants.ErrorSessionTimeout || err?.status === 401) {
              this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
            }
          },
        });
      }
    });
  }

  public getColorCode(status: number): string {
    return JobStatus[status];
  }

  public isNotReadyForCompare(status: number): boolean {
    return status !== JobStatus.Ready_To_Compare;
  }

  private getSquareBracketOrRedactionReport(element: JobDto, filename: string): void {
    const fileName = `${element.id}${filename}`;
    const removeFileName = element.englishFileLocation?.split('/').pop()!;
    const blobName = element.englishFileLocation?.replace(removeFileName, fileName)!;
    DataDogService.logDownloadFile(element.jobIdentity, fileName);
    this.fileStoreService.downloadFile(blobName, fileName);
  }

  private subscribeSignalRMessages(): void {
    this.signalRService.hubConnection.on('BroadcastMessage', (signalrData: JobDto) => {
      let jobData = this.dataSource.data;
      LogService.Debug(signalrData);
      const index = jobData.findIndex((x) => x.jobIdentity === signalrData.jobIdentity);
      if (index > -1) {
        switch (signalrData.actionType) {
          case ActionType.UpdateJobStatus:
            if (signalrData.status === JobStatus.Completed) {
              jobData.splice(index, 1);
            } else {
              jobData[index].status = signalrData.status;
            }
            break;
          case ActionType.UpdateJobDetails:
            jobData[index].status = signalrData.status;
            jobData[index].englishFilePageCount = signalrData.englishFilePageCount;
            jobData[index].chineseFilePageCount = signalrData.chineseFilePageCount;
            break;
          case ActionType.UpdateBookmark:
            jobData[index].hasBookmarkedPage = signalrData.hasBookmarkedPage;
            break;
          case ActionType.UpdateAnnotation:
            jobData[index].hasAnnotatedPage = signalrData.hasAnnotatedPage;
            jobData[index].hasAnnotationDetails = signalrData.hasAnnotationDetails;
            jobData[index].file1AnnotatedPages = signalrData.file1AnnotatedPages;
            jobData[index].file2AnnotatedPages = signalrData.file2AnnotatedPages;
            break;
          case ActionType.UpdateJobPrimaryFunctionalRole:
            if (jobData[index].primaryFunctionalRoleIdentity != signalrData.primaryFunctionalRoleIdentity) {
              jobData.splice(index, 1);
            }
            break;
        }
      } else {
        if (signalrData.actionType === ActionType.AddJob) {
          // job does not exists
          if (signalrData.typeId) {
            signalrData.typeName = this.getFeatureType(signalrData.typeId);
          }
          jobData.push(signalrData);
          jobData.sort((a, b) => b.id - a.id);
        }
      }
      this.dataSource.data = [...jobData];
      this.filterTableData(this.dashboardService.dashboardListPageIndex, this.dashboardService.dashboardListPageSize);
    });
  }

  private joinSignalRGroup() {
    if (this.signalRService.connectionState === SignalRConnectionStatus.Connected) {
      if (!this.groupJoined) {
        this.groupJoined = true;
        this.signalRService.joinGroup(this.jobListGroup);
      } else {
        LogService.Debug(`group already Joined`);
      }
    } else {
      this.groupJoined = false;
      LogService.Debug(`SignalR not connected. Status: ${this.signalRService.connectionState}`);
    }
  }

  public dropdownData(element: JobDto) {
    const data = [
      { text: AppConstants.RedactionReportLabel, visible: element?.typeId === FeatureType.Call_Redaction },
      { text: AppConstants.ExportBookmarkReportTitle, visible: element?.hasBookmarkedPage },
      { text: AppConstants.DownloadFile1AnnotatedPdfTitle, visible: element?.file1AnnotatedPages > 0 },
      { text: AppConstants.DownloadFile2AnnotatedPdfTitle, visible: element?.file2AnnotatedPages > 0 },
      { text: AppConstants.ExportAnnotationSummaryReportTitle, visible: element?.hasAnnotatedPage },
      { text: AppConstants.ExportAnnotationDetailsReportTitle, visible: element?.hasAnnotationDetails },
      { text: AppConstants.SquareBracketReportTitle, visible: element?.typeId === FeatureType.Square_Bracket_Check },
    ];

    return data.filter((x) => x.visible).map((y) => y.text);
  }

  public itemClicked(event: any, element: JobDto): void {
    switch (event) {
      case AppConstants.RedactionReportLabel:
        this.getSquareBracketOrRedactionReport(element, this.appConstants.RedactionReport);
        break;
      case AppConstants.ExportBookmarkReportTitle:
        this.GetBookmarkedPagesReport(element.jobIdentity);
        break;
      case AppConstants.ExportAnnotationDetailsReportTitle:
        this.GetLQAReport(element.jobIdentity);
        break;
      case AppConstants.DownloadFile1AnnotatedPdfTitle:
        this.downloadAnnotatedPdf(element.jobIdentity, LanguageType.English);
        break;
      case AppConstants.DownloadFile2AnnotatedPdfTitle:
        this.downloadAnnotatedPdf(element.jobIdentity, LanguageType.Chinese);
        break;
      case AppConstants.ExportAnnotationSummaryReportTitle:
        this.getAnnotationSummaryReport(element.jobIdentity);
        break;
      case AppConstants.SquareBracketReportTitle:
        this.getSquareBracketOrRedactionReport(element, this.appConstants.SquareBracketReport);
        break;
    }
  }

  public showUsageReportDialog(): void {
    const dialogRef = this.dialogService.open(UsageReportComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  public isSingleFileJobType(typeId: number, chinesefileIdentity: number | null) {
    return (
      typeId === FeatureType.Chinese_Characters_Conversion_Check ||
      typeId === FeatureType.Financial_Table_Check ||
      (typeId === FeatureType.Square_Bracket_Check && !chinesefileIdentity)
    );
  }

}
