import { Component, Input, Output, EventEmitter} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-vertical-toolbar',
  templateUrl: './vertical-toolbar.component.html',
  styleUrls: ['./vertical-toolbar.component.css'],
})
export class VerticalToolbarComponent {
  @Input() toolbarPlacement!: number; // 0 - left; 1 - right;
  @Input() acceptAndRejectDisabled!: boolean;
  @Input() isBookmarked!: boolean;
  @Input() isPageLocked!: boolean;
  @Input() isJobLocked!: boolean;
  @Input() hasNoFacts!: boolean;
  @Input() hasSamePageCount!: boolean;
  @Input() hasUndoHistory!: boolean;
  @Output() accept = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() acceptAndReject = new EventEmitter();
  @Output() swap = new EventEmitter();
  @Output() bookmarkPage = new EventEmitter();
  @Output() resetPageStatus = new EventEmitter();
  @Output() undo = new EventEmitter();
  @Output() annotate = new EventEmitter();
  @Output() rotatePage = new EventEmitter();

  public isSquareBracketCheckType!: boolean;
  public isFinancialCheckType: boolean = false;

  public readonly appConstants: typeof AppConstants = AppConstants;

  public getPositionClass(): TooltipPosition {
    return this.toolbarPlacement === 0 ? 'right' : 'left';
  }

  onAcceptClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.accept.emit();
  }

  onRejectClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.reject.emit();
  }

  onAcceptAndRejectClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.acceptAndReject.emit();
  }

  onSwapClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.swap.emit();
  }

  onBookmarkPage($event: MouseEvent): void {
    $event.stopPropagation();
    this.bookmarkPage.emit();
  }

  onResetPageStatus($event: MouseEvent): void {
    $event.stopPropagation();
    this.resetPageStatus.emit();
  }

  onUndoClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.undo.emit();
  }

  onAnnotateClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.annotate.emit();
  }

  onRotatePage(): void {
    this.rotatePage.emit();
  }
}
