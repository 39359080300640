<span kendoTooltip [title]="tooltip">
  <kendo-formfield>
    <kendo-switch
      #switchFld
      [id]="id"
      [formControl]="control"
      [onLabel]="onLabel"
      [offLabel]="offLabel"
      [readonly]="readonly"
      [size]="size"
      [style]="style"
      (valueChange)="valueChange.emit($event)"></kendo-switch>
    <kendo-formhint>{{ hint }}</kendo-formhint>
  </kendo-formfield>
</span>
