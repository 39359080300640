import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppConstants } from 'src/app/app.constants';

import { JobInformation } from 'src/app/models/job-information.model';

import { FeatureType } from '../../../core/enums/feature-type.enum';

import { DataDogService } from 'src/app/services/data-dog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FileStoreService } from 'src/app/services/file-store.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-job-information-dialog',
  templateUrl: './job-information-dialog.component.html',
  styleUrls: ['./job-information-dialog.component.css'],
})
export class JobInformationDialogComponent implements OnInit {
  public readonly jobNameLabel: string = AppConstants.JobNameLabel;
  public readonly jobInformation: JobInformation = this.data.jobInfo;
  public readonly englishFileNameLabel: string =
    this.data.jobInfo.typeId !== FeatureType.Chinese_Characters_Conversion_Check &&
    this.data.jobInfo.typeId !== FeatureType.Financial_Table_Check
      ? AppConstants.EnglishFileNameLabel
      : AppConstants.FileNameLabel;
  public readonly chineseFileNameLabel: string = AppConstants.ChineseFileNameLabel;
  public readonly creationDateLabel: string = AppConstants.CreationDateLabel;
  public readonly lockedPagesLabel: string = AppConstants.LockedPagesLabel;
  public readonly englishPageCount: string =
    this.data.jobInfo.typeId !== FeatureType.Chinese_Characters_Conversion_Check &&
    this.data.jobInfo.typeId !== FeatureType.Financial_Table_Check
      ? AppConstants.EnglishPageCount
      : AppConstants.FilePageCountLabel;
  public readonly chinesePageCount: string = AppConstants.ChinesePageCount;
  public isChineseCharactersConversionCheck: boolean = false;
  public isFinancialCheckType: boolean = false;
  public isSingleFileType: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<JobInformationDialogComponent>,
    private dialogService: DialogService,
    private fileStoreService: FileStoreService,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: { width: string; title: string; jobInfo: JobInformation }
  ) {}

  ngOnInit() {
    this.dialogService.CloseOnEscapeKey(this.dialogRef);
    this.dialogService.CloseOnBackdropClick(this.dialogRef);
    this.isSingleFileType =
      this.jobInformation.typeId === FeatureType.Chinese_Characters_Conversion_Check ||
      this.jobInformation.typeId === FeatureType.Financial_Table_Check ||
      !(this.jobInformation.chineseFileIdentity ? 1 : 0);
  }

  public openFile(blobName: string, fileName: string) {
    DataDogService.logDownloadFile(blobName, fileName);
    this.fileStoreService.downloadFile(blobName, fileName);
  }
}
