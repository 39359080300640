import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ButtonModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { CardModule, LayoutModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ICON_SETTINGS, IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ListViewModule } from '@progress/kendo-angular-listview';

import { MdePopoverModule } from '@psyx-or/material-extended-mde';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SafePipeModule } from 'safe-pipe';
import { ToastrModule } from 'ngx-toastr';

import {
  BookmarkViewService,
  LinkAnnotationService,
  MagnificationService,
  NavigationService,
  PdfViewerModule,
  TextSearchService,
  TextSelectionService,
  ThumbnailViewService,
  ToolbarService,
} from '@syncfusion/ej2-angular-pdfviewer';
import { DropDownButtonModule as SyncFusionDropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ButtonModule as SyncfusionButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';

import { AppRoutingModule } from './app-routing.module';
import { MaterialDesignModule } from './shared/modules/material/material-design.module';

import { NumericDirective } from './directives/numeric.directive';

import { HttpRequestInterceptor } from './core/interceptor/http-request.Interceptor';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './core/interceptor/timeout.Interceptor';

import { ErrorMessagePipe } from './core/pipes/error-message.pipe';
import { JobStatusPipe } from './core/pipes/job-status.pipe';
import { JobStatusAbbreviatedPipe } from './core/pipes/job-status-abbreviated.pipe';
import { CurrentIndexValuePipe } from './core/pipes/current-index-value.pipe';
import { InvalidDataErrorMessagePipe } from './core/pipes/invalid-data-error-message.pipe';

import { AboutAppComponent } from './components/about-app/about-app.component';
import { AcsPdfViewerComponent } from './shared/acs-pdf-viewer/acs-pdf-viewer.component';
import { AddEditErrorCategoryDialogComponent } from './components/pdf-viewer/add-edit-error-category/add-edit-error-category-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { AppComponent } from './app.component';
import { CanvasComponent } from './components/file-compare/canvas/canvas.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardFooterComponent } from './components/dashboard/dashboard-footer/dashboard-footer.component';
import { DashboardGridComponent } from './components/dashboard/dashboard-grid/dashboard-grid.component';
import { DashboardHeaderComponent } from './components/dashboard/dashboard-header/dashboard-header.component';
import { DataPrivacyNoticeComponent } from './components/data-privacy-notice/data-privacy-notice.component';
import { EmailOtpVerificationComponent } from './components/email-otp-verification/email-otp-verification.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FileCompareComponent } from './components/file-compare/file-compare.component';
import { FileUploadComponent } from './components/start-new-comparison/file-upload/file-upload.component';
import { GlossaryManagementComponent } from './components/glossary-management/glossary-management.component';
import { GoToPageComponent } from './components/file-compare/go-to-page/go-to-page.component';
import { ImportPagesComponent } from './components/pdf-viewer/import-pages/import-pages.component';
import { JobInformationDialogComponent } from './components/file-compare/job-info/job-information-dialog.component';
import { KendoButtonComponent } from './shared/kendo-button/kendo-button.component';
import { KendoCheckboxFieldComponent } from './shared/kendo-checkbox-field/kendo-checkbox-field.component';
import { KendoDropdownButtonComponent } from './shared/kendo-dropdown-button/kendo-dropdown-button.component';
import { KendoIconButtonComponent } from './shared/kendo-icon-button/kendo-icon-button.component';
import { KendoSingleSelectFieldComponent } from './shared/kendo-single-select-field/kendo-single-select-field.component';
import { KendoSwitchFieldComponent } from './shared/kendo-switch-field/kendo-switch-field.component';
import { KendoTextFieldComponent } from './shared/kendo-text-field/kendo-text-field.component';
import { LoginComponent } from './components/login/login.component';
import { PaginatorComponent } from './components/file-compare/paginator/paginator.component';
import { PDFViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerToolbarComponent } from './components/pdf-viewer/pdf-viewer-toolbar/pdf-viewer-toolbar.component';
import { ProgressComponent } from './shared/progress/progress.component';
import { ReadAcceptSectionComponent } from './components/read-accept-section/read-accept-section.component';
import { StartNewComparisonComponent } from './components/start-new-comparison/start-new-comparison.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UploadGlossaryComponent } from './components/glossary-management/upload-glossary/upload-glossary.component';
import { UsageReportComponent } from 'src/app/components/usage-report/usage-report.component';
import { VerticalToolbarComponent } from './components/file-compare/vertical-toolbar/vertical-toolbar.component';

const toastrOptions = {
  positionClass: 'toast-bottom-right',
  timeOut: 0,
  preventDuplicates: true,
  maxOpened: 10,
  autoDismiss: true,
  closeButton: true,
};

const datePickerOptions = {
  customRangeLabel: 'Custom range',
  displayFormat: 'DD MMM YYYY',
  separator: ' to ',
};

@NgModule({
  declarations: [
    AboutAppComponent,
    AcsPdfViewerComponent,
    AddEditErrorCategoryDialogComponent,
    AlertDialogComponent,
    AppComponent,
    CanvasComponent,
    ConfirmDialogComponent,
    ContactUsComponent,
    CurrentIndexValuePipe,
    DashboardComponent,
    DashboardFooterComponent,
    DashboardGridComponent,
    DashboardHeaderComponent,
    DataPrivacyNoticeComponent,
    EmailOtpVerificationComponent,
    ErrorMessagePipe,
    ErrorPageComponent,
    FileCompareComponent,
    FileUploadComponent,
    GlossaryManagementComponent,
    GoToPageComponent,
    ImportPagesComponent,
    InvalidDataErrorMessagePipe,
    JobInformationDialogComponent,
    JobStatusAbbreviatedPipe,
    JobStatusPipe,
    KendoButtonComponent,
    KendoCheckboxFieldComponent,
    KendoDropdownButtonComponent,
    KendoIconButtonComponent,
    KendoSingleSelectFieldComponent,
    KendoSwitchFieldComponent,
    KendoTextFieldComponent,
    LoginComponent,
    NumericDirective,
    PaginatorComponent,
    PDFViewerComponent,
    PdfViewerToolbarComponent,
    ProgressComponent,
    ReadAcceptSectionComponent,
    StartNewComparisonComponent,
    TermsAndConditionsComponent,
    UploadGlossaryComponent,
    UsageReportComponent,
    VerticalToolbarComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CardModule,
    CommonModule,
    DialogsModule,
    DropDownButtonModule,
    DropDownsModule,
    FormsModule,
    HttpClientModule,
    IconsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    ListViewModule,
    MaterialDesignModule,
    MdePopoverModule,
    NgxDaterangepickerMd.forRoot(datePickerOptions),
    NgxDropzoneModule,
    NgxMatSelectSearchModule,
    PdfViewerModule,
    ReactiveFormsModule,
    SafePipeModule,
    SyncfusionButtonModule,
    SyncFusionDropDownButtonModule,
    TextBoxModule,
    ToastrModule.forRoot(toastrOptions),
    ToolbarModule,
    TooltipModule,
  ],
  exports: [
    AboutAppComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    JobInformationDialogComponent,
    MaterialDesignModule,
    StartNewComparisonComponent,
  ],
  entryComponents: [],
  providers: [
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 60000,
    },
    {
      provide: ICON_SETTINGS,
      useValue: { type: 'font' },
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
