import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import {
  AjaxRequestInitiateEventArgs,
  AjaxRequestSettingsModel,
  AnnotationAddEventArgs,
  AnnotationDataFormat,
  AnnotationMoveEventArgs,
  AnnotationRemoveEventArgs,
  AnnotationResizeEventArgs,
  AnnotationSelectEventArgs,
  AnnotationService,
  AnnotationSettingsModel,
  ArrowSettingsModel,
  ContextMenuItem,
  ContextMenuSettingsModel,
  ExportStartEventArgs,
  FontStyle,
  FreeTextSettingsModel,
  HighlightSettingsModel,
  ImportSuccessEventArgs,
  InkAnnotationSettingsModel,
  LineSettingsModel,
  LinkAnnotationService,
  LoadEventArgs,
  MagnificationService,
  NavigationService,
  PdfViewerComponent,
  TextSelectionService,
  ToolbarService,
  UnderlineSettingsModel,
} from '@syncfusion/ej2-angular-pdfviewer';

import { AppConstants } from 'src/app/app.constants';

import { CalloutFreetextBounds } from 'src/app/models/callout-freetext-bounds.model';
import { FinancialCheckNumber, FinancialCheckRegion, FinancialCheckType } from 'src/app/models/financial-check-region.model';
import { PdfAnnotationSizeModel } from 'src/app/models/pdf-annotation-size.model';
import { PdfPageSizeModel } from 'src/app/models/pdf-page-size-model';
import { PdfPointModel } from 'src/app/models/pdf-point.model';

import { AppDataService } from 'src/app/services/app-data.service';
import { FileCompareService } from 'src/app/services/file-compare.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PdfViewerService } from 'src/app/services/pdf-viewer.service';

import { PdfViewerToolbarComponent } from './pdf-viewer-toolbar/pdf-viewer-toolbar.component';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [AnnotationService, LinkAnnotationService, NavigationService, TextSelectionService, ToolbarService,MagnificationService],
})
export class PDFViewerComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('leftPdfViewer') public readonly leftPdfViewer!: PdfViewerComponent;
  @ViewChild('rightPdfViewer') public readonly rightPdfViewer!: PdfViewerComponent;
  @ViewChild('leftPdfViewerToolbar') public readonly leftPdfViewerToolbar!: PdfViewerToolbarComponent;
  @ViewChild('rightPdfViewerToolbar') public readonly rightPdfViewerToolbar!: PdfViewerToolbarComponent;

  @Input() leftdocument: string = '';
  @Input() jobIdentity!: string;
  @Input() pageIdentity!: number;
  @Input() pageSequence!: number;
  @Input() leftFileIdentity!: number;
  @Input() rightFileIdentity!: number;
  @Input() rightdocument: string = '';
  @Input() pdfViewerSide!: string;
  @Input() accessToken!: string;
  @Input() monolingualSearch: boolean = false;
  @Input() isFinancialCheck: boolean = false;
  @Input() isPageLocked: boolean = false;

  @Output() closeLeftPdfViewer = new EventEmitter();
  @Output() closeRightPdfViewer = new EventEmitter();
  @Output() showLoader = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter();

  private apiPath = (window as any)[AppConstants.AppSettings].common.apiPath;

  private guid: string = uuidv4();
  public isDocumentLoaded: boolean = false;
  public existingAnnotationCollection: string = '[]';
  public isLineAnnotation: boolean = false;
  public isProgressBarShown: boolean = false;

  public textStyleSelected!: string[];

  public readonly ajaxRequestSettings: AjaxRequestSettingsModel = {
    ajaxHeaders: [],
    withCredentials: false,
  };
  public readonly serviceUrl: string = `${this.apiPath}api/PdfViewer`;
  private readonly baseFreeTextSize: PdfAnnotationSizeModel = { Width: 150, Height: 60 };
  public readonly contextMenuSettings: ContextMenuSettingsModel = {
    contextMenuItems: [ContextMenuItem.Delete, ContextMenuItem.Highlight, ContextMenuItem.Underline],
  };

  public readonly annotationSettings: AnnotationSettingsModel = {
    author: this.appData.user.displayName,
    // isLock: false,
    skipPrint: true,
    subject: '',
  };
  public readonly freeTextSettings: FreeTextSettingsModel = {
    borderWidth: 1,
    borderColor: '#eb4760',
    fillColor: 'rgba(255,249,196,1)',
    minHeight: 30,
    author: this.appData.user.displayName,
  };

  public readonly inkAnnotationSettings: InkAnnotationSettingsModel = {
    strokeColor: '#eb4760',
    // isLock: false,
  };

  public readonly arrowSettings: ArrowSettingsModel = {
    lineHeadStartStyle: 'Closed',
    lineHeadEndStyle: 'None',
  };

  public highlightSettings: HighlightSettingsModel = { color: '#ffff80' };
  public underlineSettings: UnderlineSettingsModel = { color: '#eb4760' };

  private leftPdfViewerPageSize!: PdfPageSizeModel;
  private rightPdfViewerPageSize!: PdfPageSizeModel;

  private financialCheckRegions: FinancialCheckRegion[] = [];
  public financialCheckTotal: number = 0;
  public hasNonPercentageValues: boolean = true;
  private pageText: string = '';
  private characterBounds: any[] = [];
  private subscription!: Subscription;

  constructor(
    private appData: AppDataService,
    private pdfViewerService: PdfViewerService,
    private notificationService: NotificationService,
    private fileCompareService: FileCompareService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageIdentity'] && changes['pageSequence']) {
      this.ajaxRequestSettings.ajaxHeaders = [
        { headerName: AppConstants.RequestIdHeader, headerValue: this.guid },
        { headerName: AppConstants.AuthorizationHeader, headerValue: this.accessToken },
        { headerName: AppConstants.PageIdentityHeader, headerValue: this.pageIdentity.toString() },
        { headerName: AppConstants.JobIdentityHeader, headerValue: this.jobIdentity },
        { headerName: AppConstants.PageSequenceHeader, headerValue: this.pageSequence.toString() },
        {
          headerName: AppConstants.FileIdentityHeader,
          headerValue: this.pdfViewerSide === 'leftPdfViewer' ? this.leftFileIdentity.toString() : this.rightFileIdentity.toString(),
        },
        {
          headerName: AppConstants.OtherFileIdentityHeader,
          headerValue: this.pdfViewerSide === 'leftPdfViewer' ? this.rightFileIdentity.toString() : this.leftFileIdentity.toString(),
        },
      ];
      if (this.leftPdfViewerToolbar) {
        this.leftPdfViewerToolbar.disablePencil(true);
      }
    }
  }

  ngAfterViewInit(): void {
    this.subscription = this.fileCompareService.resetPageSubject.subscribe((isPageLocked: boolean) => {
      this.leftPdfViewer.annotationSettings.isLock = isPageLocked;
      for (const annotation of this.leftPdfViewer.annotationCollection) {
        annotation.annotationSettings.isLock = isPageLocked;
        this.leftPdfViewer.annotation.editAnnotation(annotation);
      }
    });
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public documentLoaded(pdfViewer: PdfViewerComponent, e: LoadEventArgs): void {
    this.pdfViewerService.hideCommentsPanel(pdfViewer);
    this.financialCheckTotal = 0;
    this.hasNonPercentageValues = true;
    this.financialCheckRegions = [];
    let annotationFileName: string;
    if (this.isLeftPdfViewer(pdfViewer)) {
      // get the page width and height and store it in leftPdfViewerPageSize
      this.leftPdfViewerPageSize = JSON.parse(e.pageData).pageSizes[0];
      annotationFileName = this.leftdocument.replace(/\.[^/.]+$/, '.xfdf');
      this.leftPdfViewer.annotationSettings.isLock = this.isPageLocked;
    } else {
      // right pdf viewer
      // get the page width and height and store it in rightPdfViewerPageSize
      this.rightPdfViewerPageSize = JSON.parse(e.pageData).pageSizes[0];
      annotationFileName = this.rightdocument.replace(/\.[^/.]+$/, '.xfdf');
      this.rightPdfViewer.annotationSettings.isLock = this.isPageLocked;
    }

    // show the comment panel if there are existing annotations
    pdfViewer.importAnnotation(annotationFileName, AnnotationDataFormat.Xfdf);
    this.isDocumentLoaded = true;
  }

  private isLeftPdfViewer(pdfViewer: PdfViewerComponent) {
    return pdfViewer.documentPath === this.leftdocument;
  }

  public importSuccess(pdfViewer: PdfViewerComponent, e: ImportSuccessEventArgs): void {
    this.existingAnnotationCollection = JSON.stringify(pdfViewer.annotationCollection);
    //to remove the comment for line annnotaion after we load annotations
    const lineAnnotation: any[] = [];
    //to change icon for pencil
    e.importData[0].signatureInkAnnotation.forEach((x: any) => {
      if (x.AnnotationType === 'Ink') {
        this.pdfViewerService.changeCommentIcon(x.AnnotName);
      }
    });

    e.importData[0].shapeAnnotation.forEach((x: any) => {
      if (x.ShapeAnnotationType === 'Line') {
        lineAnnotation.push(x);
      }
      if (x.ShapeAnnotationType === 'Square' && this.pdfViewerService.isFinancialCheckRegion(JSON.parse(x.ExistingCustomData)?.type)) {
        this.pdfViewerService.removeCommentDiv(x.AnnotName);
      }
    });
    const annotation = pdfViewer.annotationCollection.filter((x) =>
      lineAnnotation.some((y) => y.ExistingCustomData && y.AnnotName === x.annotationId)
    );
    if (annotation.length > 0) {
      annotation.forEach((x) => {
        this.pdfViewerService.removeCommentDiv(x.annotationId);
      });
    }
    //to change the imported callout annotaion icon
    const freetextAnnotation = pdfViewer.annotationCollection.filter((x) =>
      lineAnnotation.some((y) => y.ExistingCustomData && JSON.parse(y.ExistingCustomData).dependsOn === x.annotationId)
    );
    if (freetextAnnotation.length > 0) {
      freetextAnnotation.forEach((x) => {
        this.pdfViewerService.changeCommentIcon(x.annotationId);
      });
    }

    const financialCheckBoxes = [...e.importData[0].shapeAnnotation.filter((x: any) => x.Subject === 'Rectangle' && x.ExistingCustomData)];
    if (financialCheckBoxes.length > 0) {
      financialCheckBoxes.forEach((x: any) => {
        const annotationBound = { left: x.Bounds.Left, top: x.Bounds.Top, width: x.Bounds.Width, height: x.Bounds.Height };
        // add the region with new position to region collection
        this.processFinancialCheckRegion(annotationBound, x.AnnotName, JSON.parse(x.ExistingCustomData).type);
      });
    }

    // to set tooltip for comment status
    const conditions: Record<string, HTMLCollectionOf<Element>> = {
      Accepted: document.getElementsByClassName('e-pv-accepted-icon'),
      Cancelled: document.getElementsByClassName('e-pv-cancelled-icon'),
      Completed: document.getElementsByClassName('e-pv-completed-icon'),
      Rejected: document.getElementsByClassName('e-pv-rejected-icon'),
    };
    this.setTitleInElement(conditions);
  }

  public annotationAdded(pdfViewer: PdfViewerComponent, e: AnnotationAddEventArgs): void {
    this.changed.emit();
    if (e.annotationType === 'Ink') {
      this.pdfViewerService.changeCommentIcon(e.annotationId);
    }
    if (this.isLineAnnotation) {
      this.isLineAnnotation = false;
      return;
    }
    if (e.annotationType === 'Rectangle') {
      const annotation = pdfViewer.annotationCollection.filter((x) => x.annotationId === e.annotationId)[0];
      if (annotation && this.pdfViewerService.isFinancialCheckRegion(annotation.customData?.type)) {
        this.pdfViewerService.removeCommentDiv(annotation.annotationId);
        this.processFinancialCheckRegion(e.annotationBound, annotation.annotationId, annotation.customData?.type);
        return;
      }
    }
    const pageSize = this.getPageSize(pdfViewer);
    if (e.annotationType === 'Line') {
      pdfViewer.enableCommentPanel = false;
      const annotation = pdfViewer.annotationCollection.filter((x) => x.annotationId === e.annotationId)[0];
      if (annotation) {
        this.pdfViewerService.removeCommentDiv(annotation.annotationId);
        const lineEndPoints: PdfPointModel | undefined = this.pdfViewerService.getAdjustedLineEndPoints(
          annotation.vertexPoints,
          this.baseFreeTextSize,
          pageSize
        );
        if (lineEndPoints) {
          pdfViewer.annotation.addAnnotation('FreeText', this.getFreeTextAnnotationSettings(lineEndPoints, e.annotationId));
          const freeTextAnnotation = pdfViewer.annotationCollection.filter(
            (x) => x.shapeAnnotationType === 'FreeText' && x.customData && x.customData.dependsOn === annotation.annotationId
          )[0];
          if (freeTextAnnotation) {
            annotation.customData = { dependsOn: freeTextAnnotation.annotationId };
            pdfViewer.annotation.editAnnotation(annotation);
            pdfViewer.annotation.selectAnnotation(freeTextAnnotation);
            this.pdfViewerService.changeCommentIcon(freeTextAnnotation.annotationId);
          }
        }
      }
    } else {
      pdfViewer.enableCommentPanel = true;
    }
  }

  private processFinancialCheckRegion(annotationBound: any, annotationId: string, type: FinancialCheckType) {
    this.financialCheckRegions.push({
      annotationId: annotationId,
      type: type,
      numbers: this.getFinancialCheckRegionNumbers(annotationBound),
    });
    this.updateFinancialCheckTotal();
  }

  private updateFinancialCheckTotal() {
    this.hasNonPercentageValues = true;
    if (this.financialCheckRegions.length > 0) {
      this.hasNonPercentageValues = this.hasNonPercentageValue(this.financialCheckRegions);
      const maxDecimalPlaces: number = this.getMaxDecimalPlaces(this.financialCheckRegions, this.hasNonPercentageValues);
      let totalSum: number = 0;
      for (const region of this.financialCheckRegions) {
        const numbersArray = region.numbers.map((num) => {
          if (!this.hasNonPercentageValues) {
            return num.value;
          }
          if (num.isPercentage) {
            return this.divideAndRound(num.value);
          }
          return num.value;
        });
        const sum = numbersArray.reduce((acc, curr) => acc + curr, 0);
        if (region.type === 'positive') {
          totalSum += sum;
        } else if (region.type === 'negative') {
          totalSum -= sum;
        }
      }
      this.financialCheckTotal = Number(totalSum.toFixed(maxDecimalPlaces));
    } else {
      this.financialCheckTotal = 0;
    }
  }

  private getMaxDecimalPlaces(regions: FinancialCheckRegion[], hasNonPercentageNumber: boolean): number {
    return Math.max(
      ...regions.map((item) => {
        let maxDecimalPlaces = 0;
        item.numbers.forEach((finCheckNumber) => {
          let finCheckValue = finCheckNumber.value;
          if (hasNonPercentageNumber && finCheckNumber.isPercentage) {
            finCheckValue = this.divideAndRound(finCheckValue);
          }
          const decimalPlaces = (finCheckValue.toString().split('.')[1] || '').length;
          maxDecimalPlaces = Math.max(maxDecimalPlaces, decimalPlaces);
        });
        return maxDecimalPlaces;
      })
    );
  }

  private divideAndRound(number: number): number {
    const precision = 1e10; // Adjust the precision as needed
    const result = Math.round((number / 100) * precision) / precision;
    return result;
  }

  private hasNonPercentageValue(financialCheckRegions: FinancialCheckRegion[]): boolean {
    const numbers = financialCheckRegions.flatMap((item) => item.numbers);
    return numbers.length > 0 ? numbers.some((numberItem) => !numberItem.isPercentage) : true;
  }

  private getFinancialCheckRegionNumbers(annotationBound: any): FinancialCheckNumber[] {
    const filteredCharsWithIndex = this.getCharacterBounds(annotationBound);
    let numberString: string = '';
    const result: FinancialCheckNumber[] = [];
    for (let i = 0; i < filteredCharsWithIndex.length; i++) {
      const elm = filteredCharsWithIndex[i];
      if (i > 0) {
        if (filteredCharsWithIndex[i - 1].index === elm.index - 1) {
          if (this.isNonFinNumber(elm)) {
            if (this.pdfViewerService.isValidNumber(numberString)) {
              result.push(this.getResultNumber(elm, numberString));
              numberString = '';
            } else {
              numberString = '';
            }
          } else {
            numberString += this.processCharacter(elm, filteredCharsWithIndex[i - 1], filteredCharsWithIndex[i + 1]);
          }
        } else {
          if (this.pdfViewerService.isValidNumber(numberString)) {
            result.push(this.getResultNumber(elm, numberString));
            numberString = '';
          }
          numberString += this.processCharacter(elm, filteredCharsWithIndex[i - 1], filteredCharsWithIndex[i + 1]);
        }
      } else {
        numberString += this.processCharacter(elm, filteredCharsWithIndex[i - 1], filteredCharsWithIndex[i + 1]);
      }
    }
    if (this.pdfViewerService.isValidNumber(numberString)) {
      result.push({ value: this.pdfViewerService.convertStringToNumber(numberString), isPercentage: false });
    }
    return result;
  }

  private getResultNumber(elm: { character: string; index: number }, numberString: string): FinancialCheckNumber {
    return { value: this.pdfViewerService.convertStringToNumber(numberString), isPercentage: elm.character === '%' };
  }

  private isNonFinNumber(elm: { character: string; index: number }) {
    return elm.character === ' ' || elm.character === '\r' || !/[0123456789,().]/.test(elm.character);
  }

  private getCharacterBounds(annotationBound: any): { character: string; index: number }[] {
    const { left: x1, width, top: y1, height } = annotationBound;
    const x2 = x1 + width;
    const y2 = y1 + height;

    const filteredCharsWithIndex = this.characterBounds
      .filter((bound) => bound.X > x1 && bound.X < x2 && bound.Y > y1 && bound.Y < y2 && bound.Width < width && bound.Height < height)
      .map(({ X, Y }) => {
        const index = this.characterBounds.findIndex((bound) => bound.X === X && bound.Y === Y);
        return {
          character: this.pageText.charAt(index),
          index,
        };
      });
    return filteredCharsWithIndex;
  }

  private readonly financialNumberRegex = /[0123456789]/;
  private processCharacter(
    elm: { character: string; index: number },
    prevElm: { character: string; index: number },
    nextElm: { character: string; index: number }
  ) {
    if (
      this.financialNumberRegex.test(elm.character) ||
      (elm.character === '.' && nextElm && this.financialNumberRegex.test(nextElm.character))
    ) {
      if (prevElm?.character && (prevElm.character === '(' || prevElm.character === '-')) {
        return `-${elm.character}`;
      }
      return elm.character;
    }
    return '';
  }

  private getFreeTextAnnotationSettings(offset: PdfPointModel, annotationId: string): any {
    return {
      offset: offset,
      pageNumber: 0,
      fontSize: 16,
      fontFamily: 'Helvetica',
      width: this.baseFreeTextSize.Width,
      height: this.baseFreeTextSize.Height,
      // isLock: false,
      defaultText: 'Type here',
      opacity: 1,
      borderColor: '#d7171f',
      borderWidth: 1,
      borderStyle: '',
      fillColor: '#fff',
      fontColor: '#000',
      fontStyle: FontStyle.None,
      textAlignment: 'Left',
      customData: { dependsOn: annotationId },
      author: this.appData.user.displayName,
    };
  }

  private getPageSize(pdfViewer: PdfViewerComponent) {
    return this.isLeftPdfViewer(pdfViewer) ? this.leftPdfViewerPageSize : this.rightPdfViewerPageSize;
  }

  public annotationRemoved(pdfViewer: PdfViewerComponent, e: AnnotationRemoveEventArgs): void {
    this.changed.emit();
    switch (e.annotationType) {
      case 'Line':
      case 'Arrow':
        // remove the free text annotation too.
        const freeTextAnnotation = pdfViewer.annotationCollection.filter(
          (x) => x.shapeAnnotationType === 'FreeText' && x.customData && x.customData.dependsOn === e.annotationId
        )[0];
        if (freeTextAnnotation) {
          pdfViewer.annotation.deleteAnnotationById(freeTextAnnotation.annotationId);
        }
        break;

      case 'FreeText':
        // remove the line annotation too.
        const lineAnnotation = pdfViewer.annotationCollection.filter(
          (x) => x.shapeAnnotationType === 'Line' && x.customData && x.customData.dependsOn === e.annotationId && !x.customData.isUndo
        )[0];
        if (lineAnnotation) {
          pdfViewer.annotation.deleteAnnotationById(lineAnnotation.annotationId);
        }
        break;

      case 'Rectangle':
        this.financialCheckRegions = this.financialCheckRegions.filter((item) => item.annotationId !== e.annotationId);
        this.updateFinancialCheckTotal();

        break;

      default:
        break;
    }

    if (pdfViewer.annotationCollection.length === 0) {
      pdfViewer.enableCommentPanel = false;
      pdfViewer.isCommandPanelOpen = false;
    }
  }

  public annotationMoved(pdfViewer: PdfViewerComponent, e: AnnotationMoveEventArgs) {
    this.changed.emit();
    const pageSize = this.getPageSize(pdfViewer);
    switch (e.annotationSettings.subject) {
      case 'Line':
        this.processLineAnnotationMoved(pdfViewer, e, pageSize);
        break;

      case 'Text Box':
        const freetextBounds: CalloutFreetextBounds = {
          annotationId: e.annotationId,
          left: (e.currentPosition as any).left,
          top: (e.currentPosition as any).top,
          width: (e.currentPosition as any).width,
        };
        this.pdfViewerService.processFreeTextAnnotation(pdfViewer, freetextBounds, pageSize);
        break;

      case 'Rectangle':
        const annotation = pdfViewer.annotationCollection.filter((x) => x.annotationId === e.annotationId)[0];
        if (annotation && this.pdfViewerService.isFinancialCheckRegion(annotation.customData?.type)) {
          // remove existing region from region collection
          this.replaceExistingFinancialCheckRegion(annotation, e.currentPosition);
        }
        break;

      default:
        break;
    }
  }

  private replaceExistingFinancialCheckRegion(annotation: any, annotationBound: any) {
    this.financialCheckRegions = this.financialCheckRegions.filter((item) => item.annotationId !== annotation.annotationId);

    // add the region with new position to region collection
    this.processFinancialCheckRegion(annotationBound, annotation.annotationId, annotation.customData?.type);
  }

  private processLineAnnotationMoved(pdfViewer: PdfViewerComponent, e: AnnotationMoveEventArgs, pageSize: PdfPageSizeModel) {
    this.pdfViewerService.processLineAnnotation(pdfViewer, e.annotationId, pageSize);
  }

  private processLineAnnotationResized(pdfViewer: PdfViewerComponent, e: AnnotationResizeEventArgs, pageSize: PdfPageSizeModel) {
    this.pdfViewerService.processLineAnnotation(pdfViewer, e.annotationId, pageSize, true);
  }

  public annotationResized(pdfViewer: PdfViewerComponent, e: AnnotationResizeEventArgs) {
    this.changed.emit();
    const pageSize = this.getPageSize(pdfViewer);
    switch (e.annotationSettings.subject) {
      case 'Line':
        this.processLineAnnotationResized(pdfViewer, e, pageSize);
        break;

      case 'Text Box':
        const freetextBounds: CalloutFreetextBounds = {
          annotationId: e.annotationId,
          left: e.annotationBound.left,
          top: e.annotationBound.top,
          width: e.annotationBound.width,
        };
        this.pdfViewerService.processFreeTextAnnotation(pdfViewer, freetextBounds, pageSize);
        break;

      case 'Rectangle':
        const annotation = pdfViewer.annotationCollection.filter((x) => x.annotationId === e.annotationId)[0];
        if (annotation && this.pdfViewerService.isFinancialCheckRegion(annotation.customData?.type)) {
          // remove existing region from region collection
          this.replaceExistingFinancialCheckRegion(annotation, e.annotationBound);
        }
        break;

      default:
        break;
    }
  }

  public exportStart(pdfViewer: PdfViewerComponent, event: ExportStartEventArgs): void {
    event.exportData.isAnnotationsExist = pdfViewer.annotationCollection.length > 0;
    event.exportData.hasRealAnnotations = pdfViewer.annotationCollection.some((x: any) => !x.customData?.type);
  }

  public exportSuccess(pdfViewer: string): void {
    this.pdfViewerService.exportPDFCompleted.next(pdfViewer);
  }

  public exportFailed(): void {
    this.notificationService.error('', AppConstants.UnexpectedError);
  }

  public commentStatusChanged(): void {
    const conditions: Record<string, HTMLCollectionOf<Element>> = {
      Accepted: document.getElementsByClassName('e-pv-accepted-icon'),
      Cancelled: document.getElementsByClassName('e-pv-cancelled-icon'),
      Completed: document.getElementsByClassName('e-pv-completed-icon'),
      Rejected: document.getElementsByClassName('e-pv-rejected-icon'),
    };
    this.setTitleInElement(conditions);
  }

  private setTitleInElement(conditions: Record<string, HTMLCollectionOf<Element>>): void {
    for (const condition in conditions) {
      for (let i = 0; i < conditions[condition].length; i++) {
        conditions[condition][i].setAttribute('title', condition);
      }
    }
  }

  public ajaxRequestSuccess(event: any): void {
    if (event.action === 'RenderPdfPages') {
      this.pageText = event.data.pageText;
      this.characterBounds = event.data.characterBounds;
    }
  }

  public ajaxRequestFailed(): void {
    const element = document.getElementsByClassName('e-dlg-container e-dlg-center-center');
    if (element) {
      const parentNode = element[0].parentNode;
      parentNode?.parentNode?.removeChild(parentNode);
    }
    this.notificationService.error('', AppConstants.UnexpectedError);
  }

  public isLineAnnotationClicked(isLineAnnotation: boolean, pdfViewer: PdfViewerComponent): void {
    const lineSettings: LineSettingsModel = {
      lineHeadStartStyle: 'None',
    };
    const calloutSettings: LineSettingsModel = {
      // isLock: true,
      lineHeadStartStyle: 'Open',
      lineHeadEndStyle: 'None',
      strokeColor: '#d7171f',
      thickness: 1,
    };
    if (isLineAnnotation) {
      pdfViewer.lineSettings = lineSettings;
      this.isLineAnnotation = true;
    } else {
      pdfViewer.lineSettings = calloutSettings;
      this.isLineAnnotation = false;
    }
  }

  public annotationSelected(event: AnnotationSelectEventArgs) {
    if (event.annotation.type === 'FreeText') {
      const obj = event.annotation.fontStyle;
      this.textStyleSelected = Object.keys(obj).filter((x) => obj[x]);
    }
    if (this.isFinancialCheck && event.annotationCollection && event.annotationCollection[0].customData?.type) {
      this.leftPdfViewer.enableCommentPanel = false;
    } else {
      this.leftPdfViewer.enableCommentPanel = true;
    }
  }

  public closeButtonClicked(event: any) {
    this.showLoader.emit(event.showFull);
    this.isProgressBarShown = !event.showFull && event.show;
  }

  public resetFiniancialCheckTotal() {
    this.financialCheckRegions = [];
    this.financialCheckTotal = 0;
  }

  public ajaxRequestinitiated(event: AjaxRequestInitiateEventArgs) {
    if (event.JsonData.action === 'Download' && this.isFinancialCheck) {
      const shapeAnnotations = JSON.parse(event.JsonData.shapeAnnotations);
      event.JsonData.shapeAnnotations = JSON.stringify([shapeAnnotations[0].filter((x: any) => !x.customData?.type)]);
      const annotationCollection = JSON.parse(event.JsonData.annotationCollection);
      event.JsonData.annotationCollection = JSON.stringify(annotationCollection.filter((x: any) => !x.customData?.type));
      if (JSON.parse(event.JsonData.annotationCollection).length === 0) {
        event.JsonData.isAnnotationsExist = false;
      }
    }
  }

  public financialRegionUndo(event: any) {
    switch (event.action) {
      case 'Drag':
      case 'Resize':
        const bounds = {
          left: event.annotation.bounds.x - event.annotation.bounds.width / 2,
          top: event.annotation.bounds.y - event.annotation.bounds.height / 2,
          width: event.annotation.bounds.width,
          height: event.annotation.bounds.height,
        };
        this.replaceExistingFinancialCheckRegion(event.annotation.annotation, bounds);
        break;

      case 'Addition':
        this.financialCheckRegions = this.financialCheckRegions.filter(
          (item) => item.annotationId !== event.annotation.annotation.annotationId
        );
        this.updateFinancialCheckTotal();
        break;

      case 'Delete':
        this.processFinancialCheckRegion(
          event.annotation.bounds,
          event.annotation.annotation.annotationId,
          event.annotation.customData?.type
        );
        break;

      default:
        break;
    }
  }

  public onKeyDown(viewer: PdfViewerComponent, event: any) {
    if (event.ctrlKey && event.key === 'z') {
      event.stopImmediatePropagation();
      if (this.isLeftPdfViewer(viewer)) {
        this.leftPdfViewerToolbar.undoButton();
      } else {
        this.rightPdfViewerToolbar.undoButton();
      }
    }
  }
}
