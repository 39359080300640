<!-- #region Jobs List Table Section -->
<div class="table-container">
  <!-- #region Jobs List Head section -->
  <div class="joblist-head-container">
    <div class="col1">
      <!-- Job list title -->
      <p class="job-list-title">{{ appConstants.JobListTitle }}</p>
      <!-- Search field -->
      <mat-form-field class="search">
        <input matInput [formControl]="jobSearchControl" (keyup)="filterJobDetails()" [placeholder]="appConstants.SearchPlaceholderTitle" />
      </mat-form-field>
    </div>
    <div class="col2">
      <div class="toggle-filter">
        <label>{{ appConstants.ShowOldJobsLabel }}</label>
        <mat-slide-toggle
          [attr.data-dd-action-name]="appConstants.ShowOldJobsLabel"
          [formControl]="toggleControl"
          color="primary"
          (change)="toggleFilter($event)"></mat-slide-toggle>
      </div>

      <!-- Usage Report button -->
      <button
        [attr.data-dd-action-name]="appConstants.UsageReportLabel"
        *ngIf="appDataService?.featurePermissions?.acs_usageReport"
        class="btn usagereport-button"
        (click)="showUsageReportDialog()">
        <p>{{ appConstants.UsageReportLabel }}</p>
        <p class="material-icons btnIcon">table_view</p>
      </button>

      <!-- Glossary Management button -->
      <button
        [attr.data-dd-action-name]="appConstants.GlossaryManagementLabel"
        *ngIf="appDataService?.featurePermissions?.acs_glossary"
        class="btn glossarymanagement-button"
        (click)="navigateToGlossaryDashboard()">
        <p>{{ appConstants.GlossaryManagementLabel }}</p>
        <p class="material-icons btnIcon">menu_book</p>
      </button>
      <!-- Start New comparison button -->

      <button
        [attr.data-dd-action-name]="appConstants.StartNewComparisonLabel"
        *ngIf="appDataService?.featurePermissions?.acs_job?.create"
        class="btn start-new-comparison-button"
        (click)="startNewComparisonJob()">
        <p>{{ appConstants.StartNewComparisonLabel }}</p>
        <p class="material-icons btnIcon">add_circle_outline</p>
      </button>
      <!-- Refresh button -->
      <button
        mat-icon-button
        [matTooltip]="appConstants.RefreshJobListTitle"
        [attr.data-dd-action-name]="appConstants.RefreshJobListTitle"
        (click)="refreshJobTable(true)"
        onclick="this.blur()"
        aria-label="refresh">
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>
  </div>
  <!-- #endregion -->

  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="appDataService?.featurePermissions?.acs_job?.view">
    <!-- JobId Column -->
    <ng-container matColumnDef="JobId">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.JobIdLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.id }} </mat-cell>
    </ng-container>

    <!-- Type Id Column -->
    <ng-container matColumnDef="TypeId">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.JobTypeNameLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.typeName }} </mat-cell>
    </ng-container>

    <!-- JobName Column -->
    <ng-container matColumnDef="JobName">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.JobNameLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.jobName }}
      </mat-cell>
    </ng-container>

    <!-- CompanyName Column -->
    <ng-container matColumnDef="CompanyName">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.CompanyNameLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.companyName }}
      </mat-cell>
    </ng-container>

    <!-- English FileName Column -->
    <ng-container matColumnDef="EngFile">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.EnglishFileLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a
        [attr.data-dd-action-name]="dataDogActions.File1Download"
        *ngIf="element?.englishFileLocation != null && !commonService.isFileNameDisabled(element?.userType)"
        [matTooltip]="element?.englishFileName"
        (click)="openFile(element?.englishFileLocation, element?.englishFileName)"
        class="cursor">
        <span class="cell_text">{{ element?.englishFileName }}</span>
        <span class="material-icons-outlined cell_icon"> cloud_download </span>
      </a>
      <span *ngIf="element?.englishFileLocation === null || commonService.isFileNameDisabled(element?.userType)">{{ element?.englishFileName }}</span>
      </mat-cell>
    </ng-container>

    <!-- English File PageCount Column -->
    <ng-container matColumnDef="EngPageCount">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.PageCountLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.englishFilePageCount }} </mat-cell>
    </ng-container>

    <!-- Chinese FileName Column -->
    <ng-container matColumnDef="ChFile">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.ChineseFileLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="isSingleFileJobType(element?.typeId, element?.chineseFileIdentity)" class="cell_text">
          {{ appConstants.NotApplicable }}
        </span>
        <a
          [attr.data-dd-action-name]="dataDogActions.File2Download"
          *ngIf="element?.typeId !== featureType.Chinese_Characters_Conversion_Check && element?.chineseFileLocation !=null && !commonService.isFileNameDisabled(element?.userType)"
          [matTooltip]="element?.chineseFileName"
          (click)="openFile(element?.chineseFileLocation, element?.chineseFileName)"
          class="cursor">
          <span class="cell_text">{{ element?.chineseFileName }}</span>
          <span class="material-icons-outlined cell_icon"> cloud_download </span>
        </a>
        <span *ngIf="element?.typeId !== featureType.Chinese_Characters_Conversion_Check && (element?.chineseFileLocation === null || commonService.isFileNameDisabled(element?.userType))">{{ element?.chineseFileName }}</span>
      </mat-cell>
    </ng-container>

    <!-- Chinese File PageCount Column -->
    <ng-container matColumnDef="ChPageCount">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.PageCountLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{
          !isSingleFileJobType(element?.typeId, element?.chineseFileIdentity) ? element?.chineseFilePageCount : appConstants.NotApplicable
        }}
      </mat-cell>
    </ng-container>

    <!-- Glossary FileName Column -->
    <ng-container matColumnDef="Glossary">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.GlossaryLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a
          [attr.data-dd-action-name]="dataDogActions.GlossaryDownload"
          [matTooltip]="element?.glossaryFileName"
          *ngIf="element?.glossaryFileName !== null && appDataService?.featurePermissions?.acs_glossary && element?.glossaryFileLocation !== null && !commonService.isFileNameDisabled(element?.userType)"
          (click)="openFile(element?.glossaryFileLocation, element?.glossaryFileName, element?.jobIdentity)"
          class="cursor">
          <span class="cell_text">{{ element?.glossaryFileName }}</span>
          <span class="material-icons-outlined cell_icon"> cloud_download </span>
        </a>
        <span *ngIf="!appDataService?.featurePermissions?.acs_glossary || element?.glossaryFileLocation === null || commonService.isFileNameDisabled(element?.userType)">{{ element?.glossaryFileName }}</span>
        <span *ngIf="!appDataService?.featurePermissions?.acs_glossary && element?.glossaryFileName === null">{{
          appConstants.NotApplicable
        }}</span>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.JobStatusLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [ngClass]="getColorCode(element?.status)" class="cell_text">
          <span class="expanded">
            {{ element?.status | jobStatus }}
          </span>
          <span class="abbreviated" [matTooltip]="commonService.getJobStatusTooltip(element?.status)">
            {{ element?.status | jobStatusAbbreviated : element?.typeId }}
          </span>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Creation Date Column -->
    <ng-container matColumnDef="CreationDate">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.CreationDateLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.createdDate }} </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="Action">
      <mat-header-cell *matHeaderCellDef> {{ appConstants.ActionLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <!-- Open Compare button -->
        <button
          [attr.data-dd-action-name]="appConstants.ComparePageLabel"
          *ngIf="appDataService?.featurePermissions?.acs_job?.compare"
          mat-icon-button
          [disabled]="isNotReadyForCompare(element?.status)"
          (click)="
            CompareFile(element?.englishFileIdentity, element?.chineseFileIdentity, element?.jobIdentity, element?.typeId, element.id)
          "
          onclick="this.blur()"
          [matTooltip]="commonService.getJobStatusTooltip(element?.status)"
          aria-label="open compare page">
          <mat-icon> compare_arrows </mat-icon>
        </button>
        <!-- Close Job button -->
        <div [matTooltip]="appConstants.CloseJobConfirmationTitle">
          <button
            [attr.data-dd-action-name]="appConstants.CloseJobConfirmationTitle"
            *ngIf="appDataService?.featurePermissions?.acs_job?.close"
            mat-icon-button
            [disabled]="element?.applicationIdentity === appList.TERA"
            (click)="closeJob(element?.jobIdentity)"
            onclick="this.blur()"
            aria-label="Close Job">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <app-dropdown-button
          *ngIf="!isNotReadyForCompare(element?.status) && dropdownData(element).length > 0"
          icon="more-vertical"
          [data]="dropdownData(element)"
          (itemClicked)="itemClicked($event, element)">
        </app-dropdown-button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="no-data" *ngIf="isNoData">
    <p>{{ appConstants.NoDataFound }}</p>
  </div>

  <mat-paginator [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</div>
