<app-progress *ngIf="isProgressBarShown" ngClass="{{ pdfViewerSide }}_progress" [visible]="isProgressBarShown" [showOverlay]="isProgressBarShown"></app-progress>
<div *ngIf="pdfViewerSide == 'leftPdfViewer'">
  <app-pdf-viewer-toolbar
    #leftPdfViewerToolbar
    [ngClass]="monolingualSearch ? 'monolingual-search' : ''"
    [documentLoaded]="isDocumentLoaded"
    [existingAnnotationCollection]="existingAnnotationCollection"
    [isFinancialCheck]="isFinancialCheck"
    [isMonolingualSearch]="monolingualSearch"
    [isPageLocked]="isPageLocked"
    [pdfFileName]="leftdocument"
    [pdfViewer]="leftPdfViewer"
    [textStylesSelected]="textStyleSelected"
    (closeLeftPdf)="closeLeftPdfViewer.emit()"
    (deleteFiniancialCheckAnnot)="resetFiniancialCheckTotal()"
    (lineAnnotation)="isLineAnnotationClicked($event, leftPdfViewer)"
    (showLoader)="closeButtonClicked($event)"
    (financialRegionUndo)="financialRegionUndo($event)">
  </app-pdf-viewer-toolbar>
  <ejs-pdfviewer
    #leftPdfViewer
    id="leftPdfViewer"
    class="pdf-viewer"
    [ajaxRequestSettings]="ajaxRequestSettings"
    [annotationSettings]="annotationSettings"
    [arrowSettings]="arrowSettings"
    [contextMenuOption]="isPageLocked ? 'None' : 'RightClick'"
    [contextMenuSettings]="contextMenuSettings"
    [documentPath]="leftdocument"
    [enableAnnotationToolbar]="false"
    [enableHyperlink]="false"
    [enableNavigationToolbar]="false"
    [enableToolbar]="false"
    [enableAutoComplete]="false"
    [freeTextSettings]="freeTextSettings"
    [highlightSettings]="highlightSettings"
    [inkAnnotationSettings]="inkAnnotationSettings"
    [serviceUrl]="serviceUrl"
    [underlineSettings]="underlineSettings"
    (ajaxRequestInitiate)="ajaxRequestinitiated($event)"
    (ajaxRequestFailed)="ajaxRequestFailed()"
    (ajaxRequestSuccess)="ajaxRequestSuccess($event)"
    (annotationAdd)="annotationAdded(leftPdfViewer, $event)"
    (annotationMove)="annotationMoved(leftPdfViewer, $event)"
    (annotationRemove)="annotationRemoved(leftPdfViewer, $event)"
    (annotationResize)="annotationResized(leftPdfViewer, $event)"
    (annotationSelect)="annotationSelected($event)"
    (commentStatusChanged)="commentStatusChanged()"
    (documentLoad)="documentLoaded(leftPdfViewer, $event)"
    (exportFailed)="exportFailed()"
    (exportStart)="exportStart(leftPdfViewer, $event)"
    (exportSuccess)="exportSuccess('leftPdfViewer')"
    (importSuccess)="importSuccess(leftPdfViewer, $event)"
    (keydown)="onKeyDown(leftPdfViewer, $event)">
  </ejs-pdfviewer>
</div>
<div *ngIf="pdfViewerSide == 'rightPdfViewer'">
  <app-pdf-viewer-toolbar
    #rightPdfViewerToolbar
    [documentLoaded]="isDocumentLoaded"
    [existingAnnotationCollection]="existingAnnotationCollection"
    [pdfViewer]="rightPdfViewer"
    [textStylesSelected]="textStyleSelected"
    (closeRightPdf)="closeRightPdfViewer.emit()"
    (lineAnnotation)="isLineAnnotationClicked($event, rightPdfViewer)"
    (showLoader)="closeButtonClicked($event)">
  </app-pdf-viewer-toolbar>
  <ejs-pdfviewer
    #rightPdfViewer
    id="rightPdfViewer"
    class="pdf-viewer"
    [ajaxRequestSettings]="ajaxRequestSettings"
    [annotationSettings]="annotationSettings"
    [arrowSettings]="arrowSettings"
    [contextMenuOption]="isPageLocked ? 'None' : 'RightClick'"
    [contextMenuSettings]="contextMenuSettings"
    [documentPath]="rightdocument"
    [enableAnnotationToolbar]="false"
    [enableHyperlink]="false"
    [enableNavigationToolbar]="false"
    [enableToolbar]="false"
    [enableAutoComplete]="false"
    [freeTextSettings]="freeTextSettings"
    [highlightSettings]="highlightSettings"
    [inkAnnotationSettings]="inkAnnotationSettings"
    [serviceUrl]="serviceUrl"
    [underlineSettings]="underlineSettings"
    (ajaxRequestFailed)="ajaxRequestFailed()"
    (annotationAdd)="annotationAdded(rightPdfViewer, $event)"
    (annotationMove)="annotationMoved(rightPdfViewer, $event)"
    (annotationRemove)="annotationRemoved(rightPdfViewer, $event)"
    (annotationResize)="annotationResized(rightPdfViewer, $event)"
    (annotationSelect)="annotationSelected($event)"
    (commentStatusChanged)="commentStatusChanged()"
    (documentLoad)="documentLoaded(rightPdfViewer, $event)"
    (exportFailed)="exportFailed()"
    (exportStart)="exportStart(rightPdfViewer, $event)"
    (exportSuccess)="exportSuccess('rightPdfViewer')"
    (importSuccess)="importSuccess(rightPdfViewer, $event)"
    (keydown)="onKeyDown(rightPdfViewer, $event)">
  </ejs-pdfviewer>
</div>
