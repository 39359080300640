import { FunctionalRole } from './functional-role.model';
import { UserRole } from './user-role.model';

export class UserInfo {
  displayName?: string;
  emailAddress?: string;
  isExternalUser?: boolean;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  loginName?: string;
  userObjectId?: string;
  functionalRoleIds: number[] | null = [];
  functionalRoleNames?: string;
  primaryFunctionalRoleIdentity?: number;
  primaryFunctionalRoleName?: string;
  userRole: UserRole[] = [];
  functionalRoles: FunctionalRole[] = [];
  companyIdentity!: string;
  userTypeId?: number;
  appRoleNames?: string;
  vendorIdentity?: string;
  vendorName?: string;
  isInternalUser!: boolean;
  isVendorUser!: boolean;
  isClientUser!: boolean;
  companyName?: string;
  termsAcceptance!: number;
}
