import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function UserNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.touched && control.value.trim() === '') {
      return { required: true };
    }
    return null;
  };
}
