<section class="vh-100 bck-image">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="login-card">
        <div class="card shadow">
          <div class="card-body py-5 px-5 text-center">
            <div class="logo d-flex justify-content-center">
              <img src="./assets/logo.png" alt="Application Logo" class="application-logo mb-3" />
            </div>
            <img src="./assets/toppan-nexus-logo_265x75.png" alt="Company Logo" class="company-logo" />
            <form [formGroup]="userLoginForm" (ngSubmit)="onSubmit()">
              <div class="form-outline mb-4">
                <input
                  type="text"
                  id="email-adddress"
                  class="form-control user-name-field"
                  formControlName="userNameControl"
                  autofocus
                  [ngClass]="userNameControl.touched && userNameControl.invalid ? 'user-name-field-err' : ''" />
                <label
                  for="userName"
                  class="form-label"
                  [ngClass]="userNameControl.touched && userNameControl.invalid ? 'user-name-label-err' : ''"
                  for="email-adddress">
                  User Name
                </label>
                <!-- need space from input field to show the spinner  -->
                <mat-spinner matSuffix class="login-spinner" [diameter]="25" *ngIf="isSubmitButtonDisabled"></mat-spinner>
                <mat-error *ngIf="userNameControl.touched && userNameControl.invalid">
                  {{ userNameControl | errorMessage : userNameError }}
                </mat-error>
              </div>
              <div class="d-grid gap-2 mb-3 continue-button">
                <button class="btn btn-primary" type="submit" [disabled]="userNameControl.invalid || isSubmitButtonDisabled || !userNameControl.value">
                  Login
                </button>
              </div>
              <!-- Checkbox -->
              <div class="form-check d-flex justify-content-center mb-4">
                <input class="form-check-input" type="checkbox" value="" id="remember" formControlName="rememberMeControl" />
                <label class="form-check-label" for="remember">Remember me</label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
