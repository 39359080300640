import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { InputSize } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-switch-field',
  templateUrl: './kendo-switch-field.component.html',
})
export class KendoSwitchFieldComponent {
  @Input() id: string | undefined = undefined;
  @Input() label: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() onLabel: string = '';
  @Input() offLabel: string = '';
  @Input() style: string = '';
  @Input() hint: string = '';
  @Input() readonly: boolean = false;
  @Input() size: InputSize = 'medium';
  @Input() verticalAlign: boolean = false;
  @Input() checked: boolean = false;
  @Input() tooltip: string = '';

  @Output() valueChange = new EventEmitter<Event>();
}
