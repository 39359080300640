<div class="header">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <span class="spacer"></span>
  <mat-icon [attr.data-dd-action-name]="'Close On JobInformation'" [mat-dialog-close]="true">close</mat-icon>
</div>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item>
      <div class="job-info-col">
        <span class="job-info-title">{{ jobNameLabel }}</span>
        <span class="job-info-values">{{ jobInformation.jobName }}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="job-info-col eng-col">
        <span class="job-info-title">{{ englishFileNameLabel }}</span>
        <span class="job-info-values">
          <a *ngIf="!commonService.isFileNameDisabled(jobInformation.userType)"
          (click)="openFile(jobInformation.englishFileLocation, jobInformation.englishFileName)"
          [matTooltip]="jobInformation.englishFileName">
            {{ jobInformation.englishFileName }}
          </a>
          <span *ngIf="commonService.isFileNameDisabled(jobInformation.userType)">{{ jobInformation.englishFileName }}</span>
        </span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="job-info-col">
        <span class="job-info-title">{{ englishPageCount }}</span>
        <span class="job-info-values">{{ jobInformation.englishFilePageCount }}</span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="!isSingleFileType">
      <div class="job-info-col">
        <span class="job-info-title">{{ chineseFileNameLabel }}</span>
        <span class="job-info-values">
          <a *ngIf="!commonService.isFileNameDisabled(jobInformation.userType)" 
          (click)="openFile(jobInformation.chineseFileLocation, jobInformation.chineseFileName)"
          [matTooltip]="jobInformation.chineseFileName">
            {{ jobInformation.chineseFileName }}
          </a>
          <span *ngIf="commonService.isFileNameDisabled(jobInformation.userType)">{{ jobInformation.chineseFileName }}</span>
        </span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="!isSingleFileType">
      <div class="job-info-col">
        <span class="job-info-title">{{ chinesePageCount }}</span>
        <span class="job-info-values">{{ jobInformation.chineseFilePageCount }}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="bottom-border-none">
        <span class="job-info-title">{{ creationDateLabel }}</span>
        <span class="job-info-values">{{ jobInformation.createdDate }}</span>
      </div>
    </mat-list-item>
  </mat-list>
</div>
