<button
  type="button"
  kendoButton
  [size]="size"
  [rounded]="rounded"
  [fillMode]="fillMode"
  [themeColor]="themeColor"
  [icon]="icon"
  [imageUrl]="imageUrl"
  [disabled]="disabled"
  [class]="rotateIconClass"
  kendoTooltip
  [title]="disabled ? '' : tooltip"
  (click)="onClick($event); $event.stopPropagation()"
  onclick="this.blur()">
  <kendo-svg-icon *ngIf="svgIcon" [icon]="svgIcon" themeColor="primary"></kendo-svg-icon>
</button>
