import { catchError, switchMap } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import { AppConstants } from 'src/app/app.constants';

import { CommonService } from 'src/app/services/common.service';
import { LogService } from 'src/app/services/log.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MsalService } from '@azure/msal-angular';
import { IAppSettings } from 'src/app/models/app-settings.model';
import { CacheLookupPolicy, SilentRequest } from '@azure/msal-browser';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestInterceptor implements HttpInterceptor {
  private msalService = inject(MsalService);
  private appUtilityService = inject(CommonService);
  private azureApiScope: string = ((<any>window)[AppConstants.AppSettings] as IAppSettings).azure.ad.scope;
  constructor(private notificationService: NotificationService, private commonService: CommonService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {
    return fromPromise(this.validateToken()).pipe(
      switchMap((result: boolean) => {
        return next.handle(this.addRequestIdHeader(request)).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    LogService.Error(error);

    let errorMessage = '';
    if (error.status === 429) {
      this.notificationService.error('', AppConstants.ErrorTooManyRequests);
      this.commonService.showSpinner.next(false);
      return of(null);
    }
    if (error.status === 401) {
      errorMessage = AppConstants.ErrorSessionTimeout;
    } else if (error.status === 403) {
      return throwError(() => error);
    } else {
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = error.error.message;
      } else {
        // server-side error
        if (error.error instanceof Array && error.error.length > 0) {
          errorMessage = error.error.map((x) => x.message).join('; ');
        } else {
          errorMessage = error.message;
        }
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  private addRequestIdHeader(request: any): HttpRequest<any> {
    const guid = uuidv4();
    return request.clone({
      setHeaders: {
        'Request-Id': guid.toString(),
      },
    });
  }

  private validateToken(): Promise<boolean> {
    return new Promise((resolve) => {
      const activeAccount = this.msalService.instance.getActiveAccount();
      let tokenExp = this.appUtilityService.getTokenExpInMinute(activeAccount?.idTokenClaims?.exp!);      
      if (tokenExp <= 20) {
        this.commonService.showSpinner.next(true);
        LogService.Info(`Token Exp is less than 20 min, Current Exp Time:${tokenExp}`);
        this.msalService
          .acquireTokenSilent({
            account: activeAccount,
            scopes: [this.azureApiScope],
            forceRefresh: false,
            cacheLookupPolicy: CacheLookupPolicy.Skip,
          } as SilentRequest)
          .subscribe({
            next: (response) => {
              this.commonService.showSpinner.next(false);
              LogService.Info('acquire token silent');
              this.msalService.instance.setActiveAccount(response.account!);
              resolve(true);
            },
          });
      } else {
        resolve(true);
      }
    });
  }
}
